*{
    box-sizing: border-box;
}
.page {
    height: 100vh;
    width: 100vw;
    background-color: lightblue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    overflow: scroll;
    max-height: 80vh;
    max-width: 80vw;
    width: 500px;
    height: 900px;
    background-color: antiquewhite;
    border-radius: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input {
    width: 70%;
    margin-bottom: 24px;
}

.permission-modal {
    padding: 12px;
    overflow: scroll;
    max-height: 90vh;
    max-width: 80vw;
    width: 500px;
    min-height: 500px;
    background-color: antiquewhite;
    border-radius: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 24px
}

.permission-modal h1 {
    margin-top: 8px;
}