html {
    border: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: scroll;
}

.frame {
    padding-top: 60px;
    height: 43vh;
    border-radius: 0 0 20% 20%;
    background: linear-gradient(rgba(99, 16, 42, 0.6), rgba(99, 16, 42, 1));
    position: relative;

}

.animal-tag-preview {
    max-width: 1000px;
    margin: 0 auto;
    overflow: scroll;
    height: 100vh;
    position: relative;
}

.frame .profile-preview-image {
    width: 60%;
    aspect-ratio: 1;
    border-radius: 50%;
    margin: 0 auto;
}

.frame .name-container {
    height: 100px;
    width: 80%;
    backdrop-filter: blur(20px);
    border-radius: 26px;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: -50px;
    left: 10%;
    box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
}

.frame .name-container .name {
    color: #333333;
    font-weight: bold;
    text-align: left;
    font-size: 36px;
}

.frame .name-container .breed {
    color: #333333;
    text-align: left;
    font-size: 16px;
}

.info {
    margin-top: 40px;
    width: 100%;
    color: #333333;
    padding: 34px;
}

.info h3 {
    text-align: left;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin: 24px 0 12px 0;
}

.info-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.info-box {
    height: 65px;
    width: 100px;
    background: #7a1837;
    border-radius: 11px;
    padding: 8px;
    color: white;
    overflow: auto;
}

.info-box h3 {
    margin: 0;
    margin-top: 4px;
    color: white;
}

.info .info-box h6 {
    color: white;
}

.info .note {
    color: #333333;
    font-size: 16px;
    text-align: left;
    margin-top: 12px;
    width: 100%;
    height: 150px;
    border-radius: 11px;
    border: #7a1837 4px solid;
    padding: 12px;
    overflow: auto;

}

.info h6 {
    text-align: left;
    color: #333333;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
}

.send-location-button {
    width: 60%;
    height: 80px;
    background: #7a1837;
    border-radius: 20px;
    position: sticky;
    bottom: 10px;
    margin: 0 auto;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.send-location-button:active {
    background: #5a122f;
    transition: color 0.3s;
}

.contact-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    border: 3px solid #7a1837;
    border-radius: 20px;
    height: 110px;
}
.contact-wrapper .left-box {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px;
    text-wrap: auto;
}

.contact-wrapper .right-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.contact-wrapper .left-box h4 {
    text-align: left;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.call-button {
    margin: 2px;
    width: 40px;
    height: 40px;
    background: #7a1837;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-decoration: none;
}