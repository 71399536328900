.admin-products {
    height: 70%;
    overflow: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.qr-code-generator{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}